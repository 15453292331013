import React from "react";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import { getStyles } from "../store/selectors";
import { sendFeedback, resetSendFeedback } from "../store/user/actions";
import ContactUsView from "../components/ContactUsView";

const mapStateToProps = (state, props) => {
  const { user, app } = state;

  return {
    appStyles: getStyles(state, props),
    user,
    app,
  };
};

const mapDisaptchToProps = (dispatch, props) => {
  return {
    resetSendFeedback: () => dispatch(resetSendFeedback()),
    sendFeedback: (feedback) => dispatch(sendFeedback(feedback)),
  };
};

export default connect(
  mapStateToProps,
  mapDisaptchToProps,
)(withTranslation(ContactUsView));
